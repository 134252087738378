/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */


const scrollTo = (id) => () => {
  const el = document.querySelector(id);
  if (el) {
    console.log(`Scrolling to ${id}`); // Log the scrolling action
    return window.scrollTo({ top: el.offsetTop - 60, left: 0, behavior: 'smooth' });
  }
  console.log(`Element with id ${id} not found`); // Log if the element is not found
  return false;
};


// Flag to indicate whether a new service worker version is available
let newVersionAvailable = false;

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Your existing code for handling the hash and scrolling
  const { hash } = location;
  if (hash) {
    console.log(`Found hash in the URL, scrolling to ${hash}`); // Log the hash-based scrolling action
    window.setTimeout(() => scrollTo(hash), 5);
  }

   // Route change event for Analytics
  const routeChangeEvent = {
    event: 'gatsby-route-change',
    path: location.pathname,
    referrer: prevLocation ? prevLocation.pathname : null
  }
  window.dataLayer = window.dataLayer || [];
  console.log('Pushing a new route change to dataLayer:', routeChangeEvent); // Log the route change event
  window.dataLayer.push(routeChangeEvent); // Push the event to the data layer

  // Check if there's a new service worker ready
  if (newVersionAvailable) {
    console.log('New service worker is ready, reloading to activate it.'); // Log the reloading due to the new service worker
    window.location.reload();
  }
};

// This function is triggered when the service worker has an update ready
export const onServiceWorkerUpdateReady = () => {
  newVersionAvailable = true;
  console.log('Service worker updated. A new version of the site will be available on next navigation.'); // Log the service worker update readiness
};
